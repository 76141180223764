<template>
  <div class="container grid px-6 mx-auto dark:bg-gray-900">
    <loading :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage">
    </loading>
    <h2 class="my-6 text-2xl font-semibold text-left text-gray-700 dark:text-gray-200">Balance de comprobaci&oacute;n</h2>

    <div class="w-full overflow-hidden rounded-lg shadow-xs">
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="w-24 px-2 py-3">Hasta: <input type="date" v-model="currentDate" /></th>
              <th class="px-2 py-3">
                <button
                  v-if="activeRequest2"
                  title="Buscar"
                  class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="mr-2 icon-arrows-cw animate-pulse" aria-hidden="true"></i>Buscar
                </button>
                <button
                  v-else
                  v-on:click="GetTrialBalance()"
                  title="Buscar"
                  class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="mr-2 icon-arrows-cw" aria-hidden="true"></i> Buscar
                </button> 
                
                <button
                  style="margin: 10px"
                  v-on:click="exportXlsx()"
                  title="Exportar"
                  class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="mr-2 icon-arrows-cw" aria-hidden="true"></i> Exportar Excel
                </button>
              </th>

                <!-- <button
                  v-on:click="hpdf()"
                  title="ExportarPDF"
                  class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="mr-2 icon-arrows-cw" aria-hidden="true"></i> Exportar PDF
                </button> -->

              
              <!-- <th class="px-2 py-3">Descripcion</th>
								<th class="px-2 py-3 w-28">Debito</th>
								<th class="px-2 py-3 w-28">Credito</th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-2 py-3">Cuenta</th>
              <!-- <th class="px-2 py-3">Descripcion</th> -->
              <th class="px-2 py-3 text-center">Deudor</th>
              <th class="px-2 py-3 text-center">Acreedor</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" id="htp">
            <tr v-for="(item, index) in objAccount.transactions" :key="index" class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3 text-xs text-left">
                <router-link
                  :to="{ name: 'ledgerAccounts', params: { url: 'entrynumber', account_id: item.account_id } }"
                  class="px-2 py-1 border-2 border-gray-300 rounded hover:bg-gray-200"
                >
                  {{ item.accountCode }} - {{ item.accountName }}
                </router-link>
              </td>
              <td class="px-4 py-3 text-xs text-center">
                {{ item.new_debit > 0 ? formatNumber(item.new_debit, ",", "$", true) : "" }}
              </td>
              <td class="px-4 py-3 text-xs text-center">
                {{ item.new_credit > 0 ? formatNumber(item.new_credit, ",", "$", true) : "" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="grid w-full grid-cols-3 gap-4 overflow-x-auto">
        <div></div>
        <div></div>
        <div class="w-full overflow-x-auto">
          <table class="w-full whitespace-no-wrap">
            <thead>
              <tr
                class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
              >
                <th class="px-4 py-3 text-center w-28">Total Debito</th>
                <th class="px-4 py-3 text-center w-28">Total Credito</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
              <tr class="text-gray-700 dark:text-gray-400">
                <td class="px-4 py-3 text-sm font-semibold">
                  {{ formatNumber(objAccount.totalDebit, ",", "$", true) }}
                </td>
                <td class="px-4 py-3 text-sm font-semibold">
                  {{ formatNumber(objAccount.totalCredit, ",", "$", true) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

// import Swal from 'sweetalert2'
import { mapState, Store } from "vuex";
import moment from "moment";
import { jsPDF } from "jspdf";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "NumberAccTransactions",
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      objAccount: [],
      activeRequest2: false,
      date: "",
      currentDate: moment().format('YYYY-MM-DD'),
      isLoading: false,
			fullPage: true,
    };
  },
  computed: {
    ...mapState(["user", "year", "access_token"]),
  },
  mounted() {},

  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    async hpdf(){
      // get the HTML source file path
      var element = document.getElementById('htp');
      var doc = new jsPDF("p", "pt", "letter");
      doc.html(element).then(()=>doc.save(`Balance de comprobación`));
		},

    async exportPdf(){
      const URL = `${this.URL}trialBalanceMethodOne`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let params = {
        company_id: this.user.companyId,
        date: this.currentDate,
        company: this.user.companyId,
        year: this.year,
      };
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      let res = rsAccount.data.data;
      data = res.transactions;
      var doc = new jsPDF("p", "pt", "letter");
      doc.setFont("helvetica", "bold"),
      doc.setFontSize(7),
      data.forEach(function(data, i){
        doc.text(30, 60 + (i * 16), 
        data.accountCode+"          "+ data.accountName
        );
      });
      doc.save(`Balance de comprobación - ${res.company}`);
    },
    async exportXlsx(){
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
      const URL = `${this.URL}trialBalanceMethodOne`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let params = {
        company_id: this.user.companyId,
        date: this.currentDate,
        company: this.user.companyId,
        year: this.year,
      };
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      let res = rsAccount.data.data;
      let options = res.transactions.map(item => {
      let accountCode = item.accountCode
      let accountName = item.accountName
      let balanceD = 0.00
      let balanceC = 0.00  
        if(item.accountNature==`D`){
          //D=D-C
          let result = item.debit-item.credit
          if(result>0){
            balanceD = result
          }else{
            balanceC = result*(-1)
          }	
        }else{
          //C=C-D
          let result = item.credit-item.debit
          if(result>0){
            balanceC = result
          }else{
            balanceD = result*(-1)
          }
          
        }
        if(balanceD<1){balanceD=''}
        if(balanceC<1){balanceC=''}	
        // Create our number formatter.
        const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',});
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501
          if(balanceD>0){balanceD = formatter.format(balanceD)}
          if(balanceC>0){balanceC = formatter.format(balanceC)}       
        return { 
          "accountCode":accountCode,"accountName":accountName,"balanceDx":balanceD,"balanceCx":balanceC
        }
      })
      const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',});
      let blank = {"accountCode":"","accountName":"","balanceD":"","balanceC":""}
      let totales = {
        "accountCode":"",
        "accountName":"",
        "balanceDx":`TOTAL DEBITO: ${formatter.format(res.totalDebit)}`,
        "balanceCx":`TOTAL CREDITO: ${formatter.format(res.totalCredit)}`
      }
      options.push(blank);
      options.push(totales);
      import("./Export2Excel").then((excel) => {
        const OBJ = options;// data json
        const multiheader = [
						["",`COMPAÑÍA: ${this.user.companyName}`,"",""],
						["","Balance de Comprobación","",""],// header in Excel
						["",`Hasta: ${this.currentDate}`,"",""]
					];
        const Header = ["Código", "Cuenta", "Deudor", "Acreedor"];// header in Excel
        const Field = ["accountCode", "accountName", "balanceDx", "balanceCx"];// Field for map with ob data json
        const Data = this.FormatJSon(Field, OBJ);// data mapped field and obj data 
        excel.export_json_to_excel({
          multiHeader: multiheader,
          header: Header, 
          data: Data,
          sheetName: moment().format('YYYY-MM-DD'),
          filename: `Balance de comprobación_${this.user.companyName}_${res.date}`,
          autoWidth: true,
          bookType: "xlsx",
        })
      })
      this.isLoading = false
    },
    FormatJSon(FilterData, JsonData) {
      return JsonData.map((v) => FilterData.map((j=>{return v[j];})))
    },
    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },

    async GetTrialBalance() {
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
      const URL = `${this.URL}trialBalanceMethodOne`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        date: this.currentDate,
        company: this.user.companyId,
        year: this.year,
      };
      // console.log(params)
      // return
      // try {
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      // this.objAccount = rsAccount.data.data
      let res = rsAccount.data.data;
      console.log("res1");
      console.log(res);

      // res.transactions.sort( (obj1, obj2) =>{
      // 	if (obj1.accountCode < obj2.accountCode) {
      // 		return -1
      // 	} else if(obj1.accountCode > obj2.accountCode){
      // 		return 1
      // 	} else {
      // 		return 0
      // 	}
      // } )
      this.objAccount = res;
      this.isLoading = false
    },
  },
  watch: {},
};
</script>

<style></style>
