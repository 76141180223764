<template>
	<div v-if="hasPermission">
		<TrialBalance />
	</div>
</template>

<script>
// @ is an alias to /src
import TrialBalance from '@/components/content/content-acc/ContentTrialBalance.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'LedgerAccounts',
	components: {
		TrialBalance,
	},
	data() {
		return {
			'hasPermission': false, 
		}
	},
	mounted() {
		if (getPermissions('accounting.report.trialbalance')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
